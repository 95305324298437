.amenities_container{
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 20px;
}

.amenities_container .amenitie_conatiner {
	text-align: center;
	width: 80px;
}



@media screen and (max-width: 840px) {

	.amenities_container{
		grid-template-columns: auto auto auto;
	}
	
}

@media screen and (max-width: 600px) {

	.amenities_container{
		grid-template-columns: auto auto;
	}
	
}


