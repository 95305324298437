/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.header-wrapper {
	background-color: #434242CC;
	position: fixed;
	z-index: 1000;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
}

.header-wrapper .header-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px 0;
	height: 84px;
}

/* HEADER LOGO*/

.header-wrapper .header-container .header-logo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.header-wrapper .header-container .header-logo .logo-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}

.header-wrapper .header-container .header-logo .btn-menu_open {
	display: block;
	margin-right: 8px;
}

.header-wrapper .header-container .header-logo .logo {
	width: 90px;
}


/* HEADER Content*/
/*-----------------------------Start Menus-----------------*/
.header-wrapper .header-container .right-menus .header-container_Menus {
	display: none;
	overflow: auto;
}

.header-wrapper .header-container .right-menus .header-container_Menus.show {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #fff;
	height: 100vh;
	width: 100%;
}

.header-wrapper .header-container .right-menus .header-container_Menus .header-container_Menus_header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 200px;
	background-color: #434242;
	position: relative;
	margin-bottom: 20px;
}

.header-wrapper .header-container .right-menus .header-container_Menus .header-container_Menus_header .btn-menu_close {
	position: absolute;
	top: 10px;
	right: 10px;
}

.header-wrapper .header-container .right-menus .header-container_Menus .header-container_Menus_header .btn-menu_close .icon {
	color: #fff
}

.header-wrapper .header-container .right-menus .header-container_Menus .header-container_Menus_header .logo {
	width: 200px;
}

.right-menus .header-container_Menus .menus_container {
	padding: 20px;
}

.right-menus .header-container_Menus .menus_container .link {
	min-height: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.right-menus .header-container_Menus .menus_container .sub-menu {
	max-height: 0;
	overflow: hidden;
	padding: 0 20px;
	-webkit-transition: max-height 500ms ease;
	-o-transition: max-height 500ms ease;
	transition: max-height 500ms ease;

}

.right-menus .header-container_Menus .menus_container .first-level_link {
	position: relative;
}

.right-menus .header-container_Menus .menus_container .first-level_link .link {
	font-weight: 700;

}

.right-menus .header-container_Menus .menus_container .second-level_link .link {
	font-weight: 500;
}

.right-menus .header-container_Menus .menus_container .first-level_link:hover .sub-menu {
	max-height: 500px;
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	transform-origin: top;
	-webkit-transition: max-height 500ms ease-out;
	-o-transition: max-height 500ms ease-out;
	transition: max-height 500ms ease-out;
}




/*-----------------------------End Menus-----------------*/

/*-----------------------------Start languages-----------------*/
.header-wrapper .header-container .right-menus .languages {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin-left: 30px;
}

.header-wrapper .header-container .right-menus .languages img {
	width: 30px;
}

/*-----------------------------End languages-----------------*/


@media screen and (min-width: 900px) {

	.header-wrapper .header-container {
		padding: 0;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	/* HEADER LOGO*/
	.header-wrapper .header-container .header-logo {
		width: auto;
	}

	.header-wrapper .header-container .header-logo .btn-menu_open {
		display: none;
	}


	/* HEADER Content*/
	/*-----------------------------Start Menus-----------------*/

	.header-wrapper .header-container .right-menus {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.header-wrapper .header-container .right-menus .header-container_Menus {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		overflow: visible;
	}

	.header-wrapper .header-container .right-menus .header-container_Menus .header-container_Menus_header {
		display: none;
	}


	.header-wrapper .header-container .right-menus .header-container_Menus .menus_container {
		padding: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.right-menus .header-container_Menus .menus_container .link-item {
		color: #fefefe;
	}

	.right-menus .header-container_Menus .menus_container .first-level_link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.right-menus .header-container_Menus .menus_container .first-level_link .link {
		font-weight: 500;
	}

	.header-wrapper .link {
		color: white;
	}

	.right-menus .header-container_Menus .menus_container .first-level_link .sub-menu {
		position: absolute;
		top: 84px;
		left: -10px;
		padding: 10px 0 0 0;
	}


	.right-menus .header-container_Menus .menus_container .first-level_link .sub-menu .sub-menu_list {
		background-color: #434242CC;
		font-size: 14px;
		color: white;
		padding: 10px;
	}

	#sub-menu-canyonig-summit {
		width: 250px;
	}


	.right-menus .header-container_Menus .menus_container .first-level_link:hover>.link,
	.header-wrapper .link:hover {
		color: #9be788;
	}
}
