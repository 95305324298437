


.parceiros-page .partners-logos {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.parceiros-page .partners-logos img {
	width: 350px;
}

@media screen and (max-width:1140px) {
	.parceiros-page .partners-logos img {
		width: 400px;
	}
}

@media screen and (max-width:860px) {
	.parceiros-page .partners-logos img {
		width: 350px;
	}
}

@media screen and (max-width:740px) {
	.parceiros-page .partners-logos img {
		width: 300px;
	}
}

@media screen and (max-width:640px) {
	.parceiros-page .partners-logos img {
		width: 400px;
	}
}

@media screen and (max-width:400px) {
	.parceiros-page .partners-logos img {
		width: 300px;
	}
}