

.contatos-page .banner-container {
	padding: 100px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}


.contatos-page .banner-content > .sectionDivider-container >.left {
	width: 100%;
	min-height: 400px;
}
.contatos-page .banner-content > .sectionDivider-container >.right {
	width: 100%;
}

.contatos-page .banner-content .data-content > .right {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px;
}

.contatos-page .banner-content .socials .link {
	margin-right: 10px;
}

.contatos-page .banner-content .data-content > .right .logo {
	max-width: 300px;
}

.contatos-page .banner-content .data-content > .right .address {
	width: 100%;
}

.contatos-page .transportes {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contatos-page .transportes .text-type {
	text-align: center;
}

.contatos-page .transportes .cards {
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 30px;
}

@media screen and (max-width:1250px) {
	.contatos-page .banner-content .data-content .sectionDivider-container {
		flex-wrap: wrap;
	}
	.contatos-page .banner-content .data-content .sectionDivider-container .section {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.contatos-page .banner-content .data-content .sectionDivider-container .right {
		margin-top: 20px;
	}
}

@media screen and (max-width:730px) {
	.contatos-page .transportes .cards {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0;
	}
}