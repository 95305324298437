.text-type {
	text-align: left;
	font-family: 'Montserrat Alternates', sans-serif;
}

.text-type.caveat {
	font-family: 'caveat';
}

.text-type.justify {
	text-align: justify;
}

.text-type.text-center {
	text-align: center;
}
.text-type.text-right {
	text-align: right;
}

.text-type.header2 {
	font-size: 31px;
}

.text-type.header1 {
	font-size: 37px;
}

.text-type.bold {
	font-weight: 700;
}

.text-type.extraBold {
	font-weight: 800;
}

.text-type.uppercase {
	text-transform: uppercase;
}