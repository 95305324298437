.escolas-page .section-1 {
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.escolas-page .banner {
	height: 1000px;
}

.escolas-page .text-wrapper .text-content .text-prizes_container .banner_samll img,
.escolas-page .banner img {
	height: 100%;
	object-fit: cover;

}

.escolas-page .text-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0px;

}

.escolas-page .text-wrapper .text-content {
	padding-top: 100px;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 450px;
	margin-left: 100px;
}


.escolas-page .text-wrapper .text-content .main-header {
	font-size: 50px;
}

.escolas-page .text-wrapper .text-content .text-prizes_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.escolas-page .text-wrapper .text-content .text-prizes_container .banner_samll {
	display: none;
	height: 600px;
}

.escolas-page .text-wrapper .text-content .text-prizes {
	width: 100%;
}

.escolas-page .text-wrapper .text-content .text-prizes .places {
	font-size: 20px;
}

.escolas-page .text-wrapper .text-content .text-prizes .description {
	display: flex;
	gap: 5px;
}


@media screen and (max-width: 11320px) {

	.escolas-page .text-wrapper .text-content {

		margin-left: 0px;
	}

	.escolas-page .text-wrapper .text-content .main-header {
		font-size: 40px;
	}
}

@media screen and (max-width: 950px) {
	.escolas-page .text-wrapper .text-content {

		margin-left: 0px;
	}

	.escolas-page .text-wrapper .text-content .main-header {
		font-size: 30px;
	}

	.escolas-page .text-wrapper .text-content .description.space-top30,
	.escolas-page .text-wrapper .text-content .text-prizes.space-top30,
	.escolas-page .text-wrapper .text-content .text-description.space-top30 {
		margin-top: 20px;
	}

	.escolas-page .text-wrapper .text-content .prizes-header {
		font-size: 20px;
	}

	.escolas-page .text-wrapper .text-content .text-prizes .places {
		font-size: 16px;
	}
}


@media screen and (max-width: 800px) {
	.escolas-page .banner {
		height: 600px;
	}

	.escolas-page .text-wrapper .text-content {

		margin-right: 300px;
	}
}

@media screen and (max-width: 620px) {
	.escolas-page .banner {
		display: none;
	}
	.escolas-page .text-wrapper {
		position: relative;
		
	}
	.escolas-page .text-wrapper .text-content {

		margin-right: 0px;
	}
}


@media screen and (max-width: 510px) {
	.escolas-page .text-wrapper .text-content .text-prizes_container .banner_samll {
		display: none;
	}
}


@media screen and (max-width: 430px) {
	.escolas-page .text-wrapper .text-content .text-prizes_container .banner_samll {
		display: none;
	}

}