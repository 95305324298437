.whatIsIt-page .main-content {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	align-items: center;
}


.whatIsIt-page .main-content .left {
	padding: 20px;
}

.whatIsIt-page .main-content .sectionDivider-container {
	align-items: center;
}

.whatIsIt-page .main-content .left .content1 {
	margin-top: 10px;	
}


