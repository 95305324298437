.banner-container {
	height: 495px;
	background-size: cover;
}


.banner-container.fullHeight {
	height: 100vh;
	min-height: 714px;
}

.banner-container.auto {
	height: auto;
}


.banner-container.auto.fullHeight {
	height: auto;
	min-height: 100vh;
}