 .programa-page .group {
 	padding-bottom: 10px;
 }

 .programa-page .group.group1 {
 	margin-bottom: 20px;
 }

 .programa-page .group.group2,
 .programa-page .group.group3 {
 	margin-left: 20px;
 }

 .programa-page .group .group-content {
 	padding: 5px 30px 5px 10px;
 	display: flex;
 	flex-direction: column;
 	border-bottom: 1px solid #71717125;
 }

 .programa-page .group.group1>.group-content {
 	border-top-left-radius: 5px;
 	border-top-right-radius: 5px;
 	background-color: #71717125;
 }

 .programa-page .group .group-content .expander-icon {
 	margin-left: 10px;
 }

 .programa-page .group .group-header {
 	min-height: 50px;
 	display: flex;
 	align-items: center;
 	justify-content: space-between;
 	width: 100%;
 }

 .programa-page .group .group-header.expander {
 	cursor: pointer;
 }

 .programa-page .group .group-header .label {
 	font-size: 16px;
 	font-weight: 700;
 	flex-grow: 1;
 }

 .programa-page .group .group-header .registration-btn {
	 width: 300px;
	 margin-top: 16px;
 }

 .programa-page .group .group-header .registration-btn .btn-text {
 	font-size: 16px;
 }


 .programa-page .group .group-header .horario {
 	font-weight: bold;
 }

 .programa-page .group-content .group-description {
 	display: flex;
 	flex-direction: column;
 }

 .programa-page .group-content .group-description .logos {
 	display: flex;
 	align-items: center;
 	justify-content: center;
 	max-height: 96px;
 	margin: 16px 0;
 }

 .programa-page .group-content .group-description .logos .logo {
 	height: 100%;
 }

 .programa-page .group-content .group-description .logos img {
 	width: 100%;
 	height: 100%;
 	object-fit: contain;
 }

 .programa-page .group .group-header .horario,
 .programa-page .group-content .group-description .group-description_row .horario {

 	margin-left: 10px;
 	min-width: 70px;
 	flex-shrink: 0;
 	text-align: right;
 }

 .programa-page .group-content .group-description .group-description_row {
 	display: flex;
 	justify-content: space-between;
 	padding: 16px 0;
 	margin-left: 24px;
 }

 .programa-page .group-content .group-description .group-description_row:not(:last-child) {
 	display: flex;
 	justify-content: space-between;
 	border-bottom: 1px solid #eee;
 	padding: 16px 0;
 }