.competicao-page .section-1 {
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.competicao-page .banner {
	height: 1000px;
}

.competicao-page .text-wrapper .text-content .text-prizes_container .banner_samll img,
.competicao-page .banner img {
	height: 100%;
	object-fit: cover;

}

.competicao-page .text-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0px;

}

.competicao-page .text-wrapper .text-content {
	padding-top: 100px;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 450px;
	margin-left: 100px;
}


.competicao-page .text-wrapper .text-content .main-header {
	font-size: 50px;
}

.competicao-page .text-wrapper .text-content .text-prizes_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.competicao-page .text-wrapper .text-content .text-prizes_container .banner_samll {
	display: none;
	height: 600px;
}

.competicao-page .text-wrapper .text-content .text-prizes {
	width: 100%;
}

.competicao-page .text-wrapper .text-content .text-prizes .places {
	font-size: 20px;
}

.competicao-page .text-wrapper .text-content .text-prizes .description {
	display: flex;
	gap: 5px;
}



.competicao-page .btn-info img {
	width: 400px;
	display: block;
}

@media screen and (max-width: 11320px) {

	.competicao-page .text-wrapper .text-content {

		margin-left: 0px;
	}

	.competicao-page .text-wrapper .text-content .main-header {
		font-size: 40px;
	}
}

@media screen and (max-width: 950px) {
	.competicao-page .text-wrapper .text-content {

		margin-left: 0px;
	}

	.competicao-page .text-wrapper .text-content .main-header {
		font-size: 30px;
	}

	.competicao-page .text-wrapper .text-content .description.space-top30,
	.competicao-page .text-wrapper .text-content .text-prizes.space-top30,
	.competicao-page .text-wrapper .text-content .text-description.space-top30 {
		margin-top: 20px;
	}

	.competicao-page .text-wrapper .text-content .prizes-header {
		font-size: 20px;
	}

	.competicao-page .text-wrapper .text-content .text-prizes .places {
		font-size: 16px;
	}
}


@media screen and (max-width: 800px) {
	.competicao-page .section-1 {
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		position: relative;
	}

	.competicao-page .text-wrapper {
		position: relative;

	}

	.competicao-page .text-wrapper .text-content {
		margin-right: 0px;
	}


	.competicao-page .text-wrapper .text-content .prizes-header {
		font-size: 30px;
	}

	.competicao-page .text-wrapper .text-content .text-prizes .places {
		font-size: 16px;
	}

	.competicao-page .banner {
		display: none;
	}

	.competicao-page .text-wrapper .text-content .text-prizes_container .banner_samll {
		display: block;
	}

	.competicao-page .btn-info img {
		width: 300px;
	}
}

@media screen and (max-width: 510px) {
	.competicao-page .text-wrapper .text-content .text-prizes_container .banner_samll {
		display: none;
	}
	.competicao-page .btn-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;	
	}

	.competicao-page .btn-info img {
		width: 300px;
	}

}


@media screen and (max-width: 430px) {
	.competicao-page .text-wrapper .text-content .text-prizes_container .banner_samll {
		display: none;
	}
	.competicao-page .btn-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;	
	}

	.competicao-page .btn-info img {
		width: 300px;
	}

}