/* .alojamento-page{
	margin-top: 100px;
} */

.alojamento-page .house-card {
	min-height: 450px;
}

.alojamento-page .house-card_description_container {
	display: flex;
	flex-direction: column;
}

.alojamento-page .house-card_description_container .text-description {
	height: 110px;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.alojamento-page .house-card_description_container .mais-info {
	align-self: flex-end;
	margin-top: 5px;
}

