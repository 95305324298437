.card-image_container {
	position: relative;
	width: 260px;
}

.card-image_container .card_image_text {
	position: absolute;
	bottom: 40px;
	color: #fefefe;
	font-size: 28px;
	font-family: 'caveat';
	text-align: center;
	width: 100%;
	
}