.link-btn {
	padding: 5px 20px;
	background-color: white;
	color: #717171;
	border: 1px solid #717171;
	display: block;

}

.link-btn:hover{
	padding: 5px 20px;
	background-color: #717171;
	color: white;
	border: 1px solid #717171;

}