.geresBiosfera-page .banner {
	height: 495px;
}

.geresBiosfera-page .main-content {
	display: flex;
	flex-direction: row;
	margin-top: 50px;
}

.geresBiosfera-page .main-content .left {
	position: relative;
}

.geresBiosfera-page .main-content .left .img{
	position: absolute;
	top: -250px;	
	width: auto;
}

.geresBiosfera-page .main-content .link-more {
	display: flex;
	justify-content: flex-end;
}

@media screen and (max-width:899.0px) {
	.geresBiosfera-page .main-content .left {
		display: none;
	}
}


