.canyons-page .main-content {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	align-items: center;
}

.canyons-page .canyon-specs_container {
	display: flex;
	flex-direction: column;
}
.canyons-page .canyon-specs_container .canyon-specs_box {
	background-color: #fff;
	margin-top: -200px;
	box-shadow: 0px 0px 24px 1px #43424277;
	padding: 24px;
}

.canyons-page .canyon-specs_container .croqui-images-container {
	width: 100%;
	margin-top: 48px;
}

.canyons-page .canyon-specs_container td {
	padding: 8px;
}

.canyons-page .canyon-specs_container table {
	width: 100%;
}

.canyons-page .canyon-specs_container .cell-label {
	min-width: 250px;
}
.canyons-page .canyon-specs_container .cell-value {
	width: 100px;
}

.canyons-page .section.right .croqui-images-container {
	display: none;
}


@media screen and (max-width:899.9px) {
	.canyons-page .section.left .canyon-specs_container .croqui-images-container {
		display: none;
	}

	.canyons-page .section.right .croqui-images-container {
		display: initial;
	}
}
