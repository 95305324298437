.card-icon_container {
	position: relative;
	display: flex;
	gap: 10px;
	align-items: center;
	margin: 20px 0;
}

.card-icon_container img {
	width: 70px;
	
}

.card-icon_container .card_icon_text {
	font-size: 16px;	
}

