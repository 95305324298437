
.oraganizacao-page {
	margin-top: 100px;
}

.oraganizacao-page .main-content .content-text {
	margin: 0 0 10px 20px;
}


.oraganizacao-page  .oraganizacao-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}


