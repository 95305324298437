.transporte-page .main-content {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
}

.transporte-page .main-content .sectionDivider-container {
	align-items: center;
}

.transporte-page .main-content .left {
	padding: 20px;
}


.transporte-page .main-content .right .header {
	font-size: 31px;
	font-family: 'caveat';
	
}

.transporte-page .main-content .right .sub-header {
	margin-top: 10px;
	font-size: 37px;

}

.transporte-page .main-content .oraganizacao-content {
	display: flex;
	flex-direction: column;
}

.transporte-page .main-content .cards {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	align-self: center;
}

