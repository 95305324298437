

.regiao-page .main-content .left {
	padding: 20px;
}

.regiao-page .main-content .right .header {
	font-size: 37px;
}

.regiao-page .main-content .right .sub-header {
	font-size: 31px;
	font-family: 'caveat';
	margin-top: 2px;

}

.regiao-page .main-content .left .content1 {
	margin-top: 10px;	
}

.regiao-page .main-content .right .destaque {
	margin-top: 40px;
	font-weight: 700;	
}


