.sectionDivider-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.sectionDivider-container>.section.left,
.sectionDivider-container>.section.right 
{
	position: relative;
}

.sectionDivider-container>.section.left.hide,
.sectionDivider-container>.section.right.hide {
	display: none;
}

.sectionDivider-container.inverted>.right
{
	order: -1;
}


@media screen and (min-width:900px) {
	.sectionDivider-container {
		flex-wrap: nowrap;
	}
	.sectionDivider-container .section {
		width: 50%;
	}

	.sectionDivider-container>.section.left.hide,
	.sectionDivider-container>.section.right.hide
	 {
		display: block;
	}
	.sectionDivider-container.inverted>.right
	{
		order: initial;
	}	

	.sectionDivider-container.gap {
		gap:30px;
	}
}
