.link-btn-img {
	position: relative;
	display: block;

}

.link-btn-img .btn-background {
	position: relative;
	height: 100%;
}


.link-btn-img .btn-text {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.link-btn-img.link {
	color: white;
}

.link-btn-img.link:hover {
	opacity: 0.9;
}