.cards-image_container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: space-between;
	gap: 10px;
}

@media screen and (min-width:851px) {
	.cards-image_container {
		gap: 10px 50px;
	}
}

@media screen and (min-width:932px) {
	.cards-image_container {
		gap: 10px 100px;
	}
}

@media screen and (min-width:1040px) {
	.cards-image_container {
		gap: 10px 150px;
	}
}

@media screen and (min-width:1145px) {
	.cards-image_container {
		gap: 10px;
	}
}
