.home-page .banner-container .home-page_banner-text-wrapper {
	display: flex;
	flex-direction: column;
	color: white;
	justify-content: flex-end;
	align-items: flex-end;
	height: 100%;
	padding-bottom: 100px;

}

.home-page .banner-container .home-page_banner-text-wrapper .registration-btn{
	width: 500px;
}


.home-page .banner-container .home-page_banner-text {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	padding: 30px 0 30px 30px;
	background-image: radial-gradient(#00000070,
			#00000070,
			#00000070,
			#00000070,
			#00000070,
			#00000070,
			#00000060,
			#00000030,
			#00000005,
			#00000005,
			#00000005,
			#00000005);
	;
}


.home-page_banner-text .banner_canyoning {
	font-size: 83px;
	color: #FFFFFF90;
	font-weight: 800;
}

.home-page_banner-text .banner_summit {
	font-size: 36px;
	font-weight: 700;
	margin-top: -10px;
	margin-bottom: 10px;
}

.home-page_banner-text .banner_location,
.home-page_banner-text .banner_date {
	font-family: 'caveat';
	font-size: 55px;
}

.home-page_banner-text .banner_date {
	margin-top: -30px;
}



/*--------------------------------------------------------------------*/



.home-page .home-page_images .home-page-cards_container {
	display: flex;
	justify-content: space-between;
	align-items: space-between;
}


.home-page .what-conatiner_description {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.home-page .what-conatiner_description>div {
	display: flex;
	flex-direction: column;
}

.home-page .what-conatiner_description .link-btn {
	margin-top: 8px;
	align-self: end;
}

.home-page .what-conatiner_description .what-conatiner_cards {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 900px) {
	.home-page .what-conatiner_description .what-conatiner_cards .sectionDivider-container.gap {
		gap: 30px;
	}
}

@media screen and (max-width: 530px) {
	.home-page_banner-text .banner_canyoning {
		font-size: 65px;
	}

	.home-page .banner-container .home-page_banner-text-wrapper .registration-btn{
		width: 400px;
	}
}

@media screen and (max-width: 460px) {
	.home-page .what-conatiner_description .what-conatiner_cards .sectionDivider-container.gap  {
		gap: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.home-page .what-conatiner_description .what-conatiner_cards .sectionDivider-container .card-icon_container {
		margin: 0 0 16px 0;
		width: 250px;
		
	}

}

@media screen and (max-width: 410px) {
	.home-page_banner-text .banner_canyoning {
		font-size: 50px;
	}

	.home-page_banner-text .banner_location,
	.home-page_banner-text .banner_date {
		font-size: 40px;
	}

	.home-page_banner-text .banner_date {
		margin-top: -20px;
	}
}