.content-fluid-container {
	width: 100vw;
	max-width:100%;
	height: 100%;
}
.content-fluid-container .content-fluid {
	max-width: 1210px;
	margin: 0 auto;
	height: 100%;
	width: 95%;
}